// CUSTOM STYLES
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Noto sans' sans-serif;
  font-size: 16px;
}

[data-theme='light'] .logo {
  color: #fff;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.modal-header {
  padding: 1rem 1.25rem;
  background-color: #f2f2f2;
}

.modal-body {
  padding: 1rem 1.25rem;
}

.modal-footer {
  padding: 1rem 1.25rem;
}

// .form-control {
//   line-height: 1;
// }

// .form-select {
//   padding: 0.2rem !important;
// }

.c-primary {
  color: $primary;
}

.c-pointer {
  cursor: pointer;
}

.f-hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid var(--kt-input-border-color);
  opacity: 0.8;
}

.row-img {
  max-width: 100px;
  height: auto;
}

.table td {
  vertical-align: middle;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.cart-pr-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // padding: .5rem;
}

.cart-pr-price {
  font-weight: 500;
  white-space: nowrap;
}

.cart-pr-title {
  text-align: left;
  font-weight: 400;
}

.cart-pr-modificator {
  font-size: 0.875rem;
  color: gray;
  font-weight: 400;
}

.cart-product {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  text-align: center;
  word-wrap: anywhere;
  border: 1px solid #f2f2f2;
  height: 100px;
  padding: 0.25rem;
  background-color: #fff;
  margin-bottom: 1.25rem;
}

.modal-80w {
  min-width: 80vw;
  // margin: auto;
}
.hidden {
  display: none !important;
}

.main-overflow-x {
  overflow-y: auto;
  overflow-x: hidden;
}

.es-modal-dialog-end {
  position: absolute;
  right: 0;
  margin: 0;
  height: 100vh;
}

.c-dropzone {
  border: 1px solid #e4e6ef;
  background: none;
  padding: 3rem 1rem !important;
}

.c-dropzone p {
  text-align: center;
}

//// LOADER ////

.ip {
  width: 16em;
  height: 8em;
}
.ip__track {
  stroke: hsl(var(--hue), 90%, 90%);
  transition: stroke var(--trans-dur);
}
.ip__worm1,
.ip__worm2 {
  animation: worm1 2s linear infinite;
}
.ip__worm2 {
  animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  .ip__track {
    stroke: hsl(var(--hue), 90%, 15%);
  }
}

/* Animation */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }
  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -358;
  }
}

.es-datepicker-wrapper input {
  margin: 0 0.4rem;
}

.card-body .table th {
  vertical-align: middle;
}

.cs-daterangepicker-wrapper {
  min-width: 210px !important;
}

.form-check-custom.form-check-sm .form-check-input {
  width: 1.125rem !important;
  height: 1.125rem !important;
}

.form-check-input[type='checkbox'] {
  border-radius: 0.32em;
}

// REACT-SELECT

.react-select__value-container {
  padding: 0 !important;
  margin: 0 !important;
}

.react-select__input-container {
  padding: 0 !important;
  margin: 0 !important;
}

// .react-select__value-container {
//   padding: 0.375rem 0.75rem !important;
//   font-size: 1rem !important;
//   font-weight: 500 !important;
//   line-height: 1.5 !important;
//   color: #5e6278 !important;
//   background-color: #ffffff !important;
//   background-clip: padding-box !important;
//   border: 1px solid #e4e6ef !important;
//   appearance: none !important;
//   border-radius: 0.475rem !important;
//   box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
// }

// .react-select__value-container {
//   padding: 0.375rem 0.75rem !important;
//   border: 1px solid #e4e6ef !important;
//   font-size: 1rem !important;
//   font-weight: 500 !important;
//   line-height: 1.5 !important;
//   border-radius: 0.475rem !important;
// }

.es-modal .modal-dialog {
  position: absolute;
  right: 0;
  margin: 0;
  transform: translateX(0%);
  width: 40%;
  max-width: none !important;
}

.es-modal .modal-dialog .modal-content {
  height: 100vh;
  overflow-y: auto;
}

.w-80vw {
  width: 80vw;
}

.client-form-card {
  width: 60vw;
}

@media (max-width: 767px) {
  .client-form-card {
    width: 80vw;
  }
}
